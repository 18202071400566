import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";
import { useClerk } from "@clerk/clerk-react";

import production_flatline from '../images/production-flatline.png';
import dashboard_screenshot from '../images/Protean_Labs_dashboard.png';
import dream_cloud from '../images/dream-cloud-flatline.png';
import pipeline_flatline from '../images/pipeline-flatline.png';
import data_processing_flatline from '../images/data-processing-flatline.png';
import language_icon_python from '../images/language-icon-python.png';
import language_icon_js from '../images/language-icon-js.png';
import language_icon_java from '../images/language-icon-java.png';
import language_icon_go from '../images/language-icon-go.png';
import language_icon_ruby from '../images/language-icon-ruby.png';

function ProductsOpenSourceSecurity() {
  const clerk = useClerk();

  return (
      <Container
        id="main"
        style={{
          paddingTop: "20px",
          paddingLeft: "auto",
          paddingRight: "auto",
          maxWidth: "960px",
        }}
      >

        <Col>
          <Row>
            <code style={{
              width: 'auto',
              margin: 'auto',
              paddingTop: '30px',
              paddingBottom: '30px',
              fontSize: '40px',
              color: 'black',
            }}>
              Software Composition Analysis
            </code>
          </Row>

          <Row>
            <p style={{
              paddingLeft: '10%',
              paddingRight: '10%',
              paddingBottom: '100px',
              fontSize: '25px',
              textAlign: 'center',
            }}>
              The practice of analyzing software to detect third-party 
              dependencies and check if they are up-to-date or contain security flaws.
            </p>
          </Row>
        </Col>

        <Container style={{textAlign: "center"}}>
          <Row>
            <Col>
              <h4 style={{
                paddingTop: '110px'
              }}>
                <code style={{ color: 'black', fontSize: '40px' }}>
                  <b>
                    Step[0]
                  </b>
                </code>

                <br />
                <br />

                <code style={{color: 'black', fontSize: '20px'}}>
                  Sign up for a Free tier account to get an API key
                </code>
              </h4>
            </Col>
            <Col style={{paddingRight: '50px'}}>
              <Image
                src={dream_cloud}
                alt="A picture of a man sleeping on a cloud"
                style={{
                  maxHeight: '450px',
                  maxWidth: '450px',
                  pointerEvents: 'none',
                  paddingTop: '50px',
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{textAlign: "center"}}>
          <Row>
            <Col>
              <h4 style={{
                paddingTop: '110px'
              }}>
                <code style={{ color: 'black', fontSize: '40px' }}>
                  <b>
                    Step[1]
                  </b>
                </code>

                <br />
                <br />

                <code style={{color: 'black', fontSize: '20px'}}>
                  Create a new CI pipeline job and add your API key as a Secret
                </code>
              </h4>
            </Col>
            <Col style={{paddingRight: '50px'}}>
              <Image
                src={pipeline_flatline}
                alt="A picture of pipes"
                style={{
                  maxHeight: '450px',
                  maxWidth: '450px',
                  pointerEvents: 'none',
                  paddingTop: '50px',
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{textAlign: "center"}}>
          <Row>
            <Col>
              <h4 style={{
                paddingTop: '110px'
              }}>
                <code style={{ color: 'black', fontSize: '40px' }}>
                  <b>
                    Step[2]
                  </b>
                </code>

                <br />
                <br />

                <code style={{color: 'black', fontSize: '20px'}}>
                  Add a new step to your pipeline job that installs the <b>protean</b> command line tool and passes it your dependencies
                </code>
              </h4>
            </Col>
            <Col style={{paddingRight: '50px'}}>
              <Image
                src={production_flatline}
                alt="A picture of a machine loading boxes onto a conveyor belt"
                style={{
                  maxHeight: '450px',
                  maxWidth: '450px',
                  pointerEvents: 'none',
                  paddingTop: '50px',
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{textAlign: "center"}}>
          <Row>
            <Col>
              <h4 style={{
                paddingTop: '110px'
              }}>
                <code style={{ color: 'black', fontSize: '40px' }}>
                  <b>
                    Step[3]
                  </b>
                </code>

                <br />
                <br />

                <code style={{color: 'black', fontSize: '20px'}}>
                  Push a code change and see the results!
                </code>
              </h4>
            </Col>

            <Col style={{paddingRight: '50px'}}>
              <Image
                src={data_processing_flatline}
                alt="A picture of a laptop showing a graph and is surrounded by tech symbols"
                style={{
                  maxHeight: '450px',
                  maxWidth: '450px',
                  pointerEvents: 'none',
                  paddingTop: '50px',
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container>
          <Col>
            <Row>
              <p style={{
                paddingLeft: '5%',
                paddingRight: '5%',
                paddingTop: '100px',
                paddingBottom: '60px',
                marginRight: 'auto',
                marginLeft: 'auto',
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                We currently support the following ecosystems:
              </p>
            </Row>
            <Row>
              <Image
                src={language_icon_python}
                alt="Python logo"
                style={{
                  pointerEvents: 'none',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <Image
                src={language_icon_js}
                alt="Javascript logo"
                style={{
                  pointerEvents: 'none',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            </Row>
            <Row>
              <p style={{
                paddingLeft: '5%',
                paddingRight: '5%',
                paddingTop: '100px',
                paddingBottom: '60px',
                marginRight: 'auto',
                marginLeft: 'auto',
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
                With more on the way!
              </p>
            </Row>

            <Row>
              <Image
                src={language_icon_go}
                alt="Goalang logo"
                style={{
                  pointerEvents: 'none',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <Image
                src={language_icon_ruby}
                alt="Ruby logo"
                style={{
                  pointerEvents: 'none',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <Image
                src={language_icon_java}
                alt="Java logo"
                style={{
                  pointerEvents: 'none',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            </Row>
          </Col>
        </Container>

        <Container style={{marginTop: '200px'}}>
          <Row style={{textAlign: 'center'}}>
            <h2>
              Our dashboard is easy to use and provides all the relevant information you care about, at a glance
            </h2>
          </Row>
          <Row>
            <Image
              src={dashboard_screenshot}
              alt="Protean Labs Dashboard Screenshot"
              style={{
                maxHeight: '450px',
                maxWidth: '850px',
                pointerEvents: 'none',
                paddingLeft: '0px',
                paddingRight: '0px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '30px',
                border: '5px solid black',
              }}
            />
          </Row>
        </Container>

        <Container>
          <Row>
            <p style={{
              marginTop: '200px',
              marginRight: 'auto',
              marginLeft: 'auto',
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingBottom: '80px',
              fontSize: '35px',
              textAlign: 'center',
            }}>
              Start now and have a Proof of Concept before lunch!
            </p>
          </Row>
        </Container>
      
        <Container>
          <Row>
            <Button
              style={{
                background: 'black',
                borderColor: 'black',
                maxWidth: '400px',
                paddingTop: '20px',
                paddingBottom: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '80px',
              }}
              onClick={() => clerk.redirectWithAuth('/dashboard')}
            >
              Sign Up →
            </Button>
          </Row>
        </Container>

      </Container>
  )
}

export default ProductsOpenSourceSecurity;