import './App.css';

// Router
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// Components
import ProteanNavBar from './components/NavBar';
import ProteanFooter from './components/Footer';
import SettingsOrganizations from './components/SettingsOrganizations';
import SettingsInvitations from './components/SettingsInvitations';
import SettingsProjects from './components/SettingsProjects';
import SettingsBilling from './components/SettingsBilling';
import SettingsApiKey from './components/SettingsApiKey';

// Pages
import ProductsOpenSourceSecurity from './pages/ProductsOpenSourceSecurity';
import Pricing from "./pages/Pricing";
import RootPage from "./pages/Root";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SecurityAndCompliance from "./pages/SecurityAndCompliance";
import DashboardPage from "./pages/Dashboard";
import DashboardScans from "./pages/DashboardScans";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
//import Careers from "./pages/Careers";
import OrganizationDetail from "./pages/OrganizationDetail";
import DashboardScanDetail from './pages/DashboardScanDetail';

// Auth
import { SignedIn, SignedOut } from "@clerk/clerk-react";

// const clerk_pub_key = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ProtectedRoute(props) {
  const { element } = props
  return (
    <>
      <SignedIn>
        {element}
      </SignedIn>
      <SignedOut>
        <Navigate to="/" />
      </SignedOut>
    </>
  )
}


function App() {
  return (
    <div className="App">
      <Router>
        <ProteanNavBar />
        <Routes>

          <Route path="/products/software-composition-analysis" element={<ProductsOpenSourceSecurity />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/security" element={<SecurityAndCompliance />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />}>
            {/* Dashboard pages */}
            <Route index element={<Navigate to="/dashboard/scans" replace />} />
            <Route path="/dashboard/scans" element={<ProtectedRoute element={<DashboardScans />} />} />
            <Route path="/dashboard/scans/:scanId" element={<ProtectedRoute element={<DashboardScanDetail />} />} />

            {/* Settings pages */}
            <Route path="/dashboard/settings/organizations" element={<ProtectedRoute element={<SettingsOrganizations />} />} />
            <Route path="/dashboard/settings/organizations/:organizationId" element={<ProtectedRoute element={<OrganizationDetail />} />} />
            <Route path="/dashboard/settings/invitations" element={<ProtectedRoute element={<SettingsInvitations />} />} />
            <Route path="/dashboard/settings/projects" element={<ProtectedRoute element={<SettingsProjects />} />} />
            <Route path="/dashboard/settings/billing" element={<ProtectedRoute element={<SettingsBilling />} />} />
            <Route path="/dashboard/settings/api-keys" element={<ProtectedRoute element={<SettingsApiKey />} />} />
          </Route>

          {/* <Route path="/organization">
            <Route path=":organizationId" element={<ProtectedRoute element={<OrganizationDetail />} />} />
          </Route> */}

          <Route path="/" element={<RootPage />} />

          {/* All other requests go to 404 Not Found page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ProteanFooter />
    </div>
  );
}

export default App;
