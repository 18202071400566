import {Col, Container, Row} from "react-bootstrap";
import ProteanLabsLogo from "./Logo";
import CookieToast from "./CookieToast";

import "./Footer.css";

function ProteanFooter() {

  const docsUrl = process.env.REACT_APP_PROTEAN_DOCS_URL;
  const blogUrl = process.env.REACT_APP_PROTEAN_BLOG_URL;

  return (
    <>
    <footer>
      <Container>
        <Row>
          <Col>
            <ProteanLabsLogo size='45' />

            <div className="status-page-link-container">
              <a target="_blank" rel="noreferrer" href="https://protean-labs.betteruptime.com/" className="status-page-link">
                All systems operational ↗
              </a>
            </div>

            <Row id="social-icon-row">
              <a href="https://github.com/proteanlabsio" target="_blank" rel="noreferrer">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="48" height="48" className="social-icon">
                  <title>GitHub</title>
                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
                </svg>
              </a>

              <a href="https://discord.gg/dVT57vBufM" target="_blank" rel="noreferrer">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="48" height="48" className="social-icon">
                  <title>Discord</title>
                  <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/>
                </svg>
              </a>

              <a href="https://www.linkedin.com/company/proteanlabsio" target="_blank" rel="noreferrer">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="48" height="48" className="social-icon">
                  <title>LinkedIn</title>
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>

              <a href="https://x.com/ProteanLabs_io" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" className="social-icon">
                  <title>X</title>
                  <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"/>
                </svg>
              </a>

            </Row>
          </Col>

          <Col>
            <ul style={{ listStyleType: "none", textAlign: "left" }}>
              <li className="colHeader">Protean Labs</li>
              <li className="colLink"><a href="/about-us">About Us</a></li>
              <li className="colLink"><a href="/contact-us">Contact Us</a></li>
              <li className="colLink"><a href="/blog/tags/news">News</a></li>
              <li className="colLink"><a href={blogUrl} rel="noreferrer">Blog</a></li>
              <li className="colLink"><a href="/security">Security</a></li>
              <li className="colLink"><a href="/products/software-composition-analysis">Software Composition
                Analysis</a></li>
            </ul>
          </Col>

          <Col>
            <ul style={{ listStyleType: "none",textAlign: 'left'}}>
              <li className="colHeader">Platform</li>
              <li className="colLink"><a href={docsUrl} rel="noreferrer">Documentation</a></li>
              <li className="colLink"><a href="https://github.com/proteanlabsio">Github</a></li>
              <li className="colLink"><a href="/pricing">Pricing</a></li>
            </ul>
          </Col>

          <Col>
            <ul style={{listStyleType: 'none',textAlign: 'left'}}>
              <li className="colHeader">Legal</li>
              <li className="colLink"><a href="/terms-of-service">Terms of Service</a></li>
              <li className="colLink"><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
          </Col>

        </Row>

        <Row>
          <div style={{
            paddingTop: '100px',
            paddingBottom: '50px',
            textAlign: 'center',
          }}>
            <hr />
            © {(new Date().getFullYear())} Protean Labs, LLC
          </div>
        </Row>

        {/* This is our Cookie Consent pop up */}
        <CookieToast />

      </Container>
    </footer>
    </>
  )
}

export default ProteanFooter;