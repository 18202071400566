import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { getOrganizations } from '../utils/apiRequests';

// React Components
import {Button, Col, Container, Row, Table} from 'react-bootstrap'
import NewOrganizationModal from "./NewOrganizationModal";
import DateTimeFormatter from "./DateTimeFormatter";
import APIErrorModal from './APIErrorModal';

import "./SettingsOrganizations.css"


function SettingsOrganizations() {
  const { getToken } = useAuth()
  const [orgs, setOrgs] = useState([])
  const [newOrgModal, setNewOrgModal] = useState(false)
  const [errorModalShow, setErrorModalShow] = useState(false)
  const [errorModalMsg, setErrorModalMsg] = useState('')

  /**
   *
   */
  async function getOrgsData() {
    let token = await getToken({ template: 'protean-default' });

    const { data, error } = await getOrganizations(token)
    // console.log('data:', data)
    // console.log('error:', error)

    if (error != null) {
      // Modal
      setErrorModalMsg(error.message)
      setErrorModalShow(true)
      setOrgs([])
    } else {
      setOrgs([...data])
    }
  }

  //
  useEffect(() => {
    getOrgsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   *
   */
  return (
    <Container className='settings-organizations'>

      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

      <NewOrganizationModal
        show={newOrgModal}
        onHide={() => {
          setNewOrgModal(false)
          getOrgsData()
        }}
      />

      <Row>
        <Col>
          <h3>Organizations</h3>
        </Col>
        <Col>
          <Button
            variant="outline-dark"
            className='create-new-button rounded-1'
            onClick={() => setNewOrgModal(true)}
          >
            New Organization
          </Button>
        </Col>
      </Row>

      <Row>
        <Table className="organizations-table">
          <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Role</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          { orgs !== null && orgs.length > 0 ? orgs.map(organizationObj => {
              return (
                  <tr key={organizationObj.id}>
                    <td><pre><a className="org-name-link" href={"/dashboard/settings/organizations/"+organizationObj.id} >{organizationObj.name}</a></pre></td>
                    <td><pre>{organizationObj.id}</pre></td>
                    <td>{organizationObj.role}</td>
                    <td><pre><DateTimeFormatter dateTimeString={organizationObj.created_at} /></pre></td>
                  </tr>
              )
            })
            :
            <tr>
              <td>
                <p>No orgs</p>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          }
          </tbody>
        </Table>
      </Row>

    </Container>
  )
}

export default SettingsOrganizations