import React from "react";
import { Container, Row, CardGroup, Card } from "react-bootstrap";

function Pricing() {
  return (
    <Container
      style={{
        minHeight: "90vh",
        paddingTop: "20px",
        paddingLeft: "auto",
        paddingRight: "auto",
      }}
    >
      <Row>
        <h2
          style={{
            width: "auto",
            margin: "auto",
            paddingTop: "70px",
            paddingBottom: "90px",
          }}
        >
          Reliable, Affordable Security for Everyone
        </h2>

        <CardGroup>
          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Founder</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 1 Seat <br />
                ✅ Up to 1 Project <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>Free</center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Startup</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 5 Seats <br />
                ✅ Up to 3 Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Email Support <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  $250/<small className="text-muted">m</small>
                </center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Business</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 50 Seats <br />
                ✅ Up to 30 Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Email Support <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  $500/<small className="text-muted">m</small>
                </center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Enterprise</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Unlimited Seats <br />
                ✅ Unlimited Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Email Support <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  Give us a call!
                </center>
              </h5>
            </Card.Footer>
          </Card>
        </CardGroup>
      </Row>
    </Container>
  );
}

export default Pricing;
