const apiUrl = process.env.REACT_APP_API_URL

/**
 * apiRequest
 * @param {*} path 
 * @param {*} method 
 * @param {*} token 
 * @param {*} params 
 * @returns 
 */
const apiRequest = async (path, method, token, params) => {
  const url = `${apiUrl}${path}`
  // console.log(`sending API request to: ${url}`)

  try {
    let reqParams = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    // add req body if there are params
    if (params && method !== "GET") { reqParams.body = JSON.stringify(params) }

    const req = await fetch(url, reqParams)
    const json = await req.json()

    const { data, error } = json
    if (error != null) {
      const jsonStr = JSON.stringify(error)

      // throw new Error(jsonStr)
      const err = new Error(jsonStr)
      return { data: null, err }
    }

    if (req.status < 200 || req.status > 299) {
      // json.detail comes from the backend
      // specifically ProteanException. See create_project for example
      let errText = data ?? json.detail ?? req.statusText

      let err = new Error(errText, { cause: req.statusText })
      err.status = req.status

      // throw err
      return { data: null, err }
    }

    // catch inconsistent API data nesting
    if (!data) {
      return {data: json, error: null}
    }

    return { data, error: null }

  } catch (error) {
    return { data: null, error }
  }
}

/**
 *
 */
export async function getOrganizationDetails(token, id) {
  return await apiRequest("/organizations/details/" + id, "GET", token)
}

/**
 *
 */
export async function getOrganization(token, id) {
  return await apiRequest("/organizations/" + id, "GET", token)
}

/**
 *
 */
export async function getOrganizations(token) {
  return await apiRequest("/organizations", "GET", token)
}

/**
 *
 */
export async function getOrganizationsAdmin(token) {
  return await apiRequest("/organizations/admin", "GET", token)
}

/**
 *
 */
export async function createOrganization(token, params, agreed_to_tos) {
  return await apiRequest(`/organizations?agreed_to_tos=${agreed_to_tos}`, "POST", token, params)
}

/**
 *
 */
export async function getProjects(token) {
  return await apiRequest("/projects", "GET", token, {})
}

/**
 *
 */
export async function createProject(token, params) {
  return await apiRequest('/projects', "POST", token, params)
}

/**
 *
 */
export async function getScans(token, projectId) {
  return await apiRequest(`/scans/project/${projectId}`, "GET", token)
}

/**
 *
 */
export async function getScan(token, scanId) {
  return await apiRequest(`/scans/${scanId}`, "GET", token)
}

/**
 *
 */
export async function getUser(token) {
  return await apiRequest("/users/me", "GET", token)
}

/**
 *
 */
export async function getUsersApiKeys(token) {
  return await apiRequest("/users/me/api-keys", "GET", token)
}

/**
 *
 */
export async function revokeApiKey(token, apiKeyId) {
  const body = {"api-key-id": apiKeyId};
  return await apiRequest("/users/me/api-keys/revoke", "DELETE", token, body)
}

/**
 *
 */
export async function createNewApiKey(token) {
  return await apiRequest("/users/me/api-keys", "POST", token)
}

/**
 *
 */
export async function getBillingManagementRedirectURL(token, orgId) {
  return await apiRequest("/billing/manage/" + orgId, "POST", token)
}

/**
 *
 */
export async function sendOrganizationInvite(token, orgId, emails) {
  const params = {"organization_id": orgId, "emails": emails};

  return await apiRequest("/invites", "POST", token, params)
}

/**
 *
 */
export async function getOrganizationInvites(token) {
  return await apiRequest("/invites", "GET", token)
}

/**
 *
 */
export async function acceptOrganizationInvite(token, inviteId) {
  return await apiRequest("/invites/" + inviteId, "POST", token)
}

/**
 *
 */
export async function declineOrganizationInvite(token, inviteId) {
  return await apiRequest("/invites/" + inviteId, "DELETE", token)
}
