import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

/**
 * Button that will copy the 'data' param to the users clipboard.
 * Ex usage:
 * <CopyToClipboardButton data={myObj.id} />
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CopyToClipboardButton(props) {
  const { data } = props
  const [copied, setCopied] = useState(false)

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1100);
  };

  return (
    <>
      <Button
        onClick={() => handleCopy(data)}
        // variant={copied ? 'outline-success' : 'outline-dark'}
        variant='link'
        size='sm'
        className="rounded-1"
        //style={{ paddingBottom: '9px' }}
      >
        { copied ? <FontAwesomeIcon icon={faCheck} style={{color: 'green'}} /> : <FontAwesomeIcon icon={faCopy} style={{color: 'black', paddingBottom: '3px'}} /> }
      </Button>
    </>
  )
}

export default CopyToClipboardButton
