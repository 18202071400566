import { useAuth } from "@clerk/clerk-react"
import React, { useEffect, useState } from "react"

// React Components
import {Container, Row, Col, Button, Table, } from "react-bootstrap"
import { getProjects } from "../utils/apiRequests"
import NewProjectsModal from "./NewProjectModal";
import DateTimeFormatter from "./DateTimeFormatter";
import CopyToClipboardButton from './CopyToClipboardButton';

// CSS imports
import "./SettingsProjects.css"


function SettingsProjects() {
  const { getToken } = useAuth()
  const [projects, setProjects] = useState([])
  const [newProjectModal, setNewProjectModal] = useState(false)

  /**
   * 
   */
  async function getProjectsData() {
    const token = await getToken({ template: 'protean-default' })
    const { data } = await getProjects(token)
    if (data) {
      setProjects([...data])
    }
  }

  // Inital component mount fetch projects data
  useEffect(() => {
    getProjectsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 
   */
  return (
    <Container className="settings-projects">
      <NewProjectsModal
        show={newProjectModal}
        onHide={() => {
          setNewProjectModal(false)
          getProjectsData()
        }}
      />
      <Row>
        <Col>
          <h3>Projects</h3>
        </Col>
        <Col>
          <Button
            variant="outline-dark"
            className="create-new-button rounded-1"
            onClick={() => { setNewProjectModal(true) }}
          >New Project
          </Button>
        </Col>
      </Row>

      <Table className="projects-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>ID</th>
          <th>Ecosystem</th>
          <th>Org</th>
          <th>Created</th>
        </tr>
        </thead>

        <tbody>
        {
          projects.length > 0 ?
            projects.map(p => {
              return (
              <tr key={p.projects_id}>
                <td>{p.projects_name}</td>
                <td><pre>{p.projects_id}{<CopyToClipboardButton data={p.projects_id} />}</pre></td>
                <td>{p.projects_ecosystem}</td>
                <td>{p.organization_name}</td>
                <td><pre><DateTimeFormatter dateTimeString={p.created_at} /></pre></td>
              </tr>
              )
            })
          :
            <tr>
              <td style={{paddingBottom: '20px'}}>No Projects (Yet!)</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
        }
        </tbody>
      </Table>

    </Container>
  )
}

export default SettingsProjects