import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import {
  Button,
  Form,
  Modal
} from "react-bootstrap"
import { createOrganization } from "../utils/apiRequests"
import APIErrorModal from "./APIErrorModal";

function NewOrganizationModal(props) {
  let emptyFormData = {
    name: ""
  }
  const [formData, setFormData] = useState(emptyFormData)
  const [submitted, setSubmitted] = useState(false)
  const [tosAgree, setTosAgree] = useState(false)
  const [errorModalShow, setErrorModalShow] = useState(false)
  const [errorModalMsg, setErrorModalMsg] = useState('')

  const { getToken } = useAuth()

  // handles the TOS agreement switch
  const handleTosAgreeSwitchEvent = (event) => {
    setTosAgree(event.target.checked);
  };

  async function formSubmit() {
    setSubmitted(true)
    const token = await getToken({ template: "protean-default" })

    // eslint-disable-next-line
    const { _, error } = await createOrganization(token, formData, tosAgree)

    if (error != null) {
      console.error(error)
      // Modal
      setErrorModalMsg(error.message || 'Your request could not be processed')
      setErrorModalShow(true)
    } else {
      props.onHide()
    }
    setSubmitted(false)
  }

  return (
    <>
      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setFormData(emptyFormData)
        setSubmitted(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          New Organization
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stark Industries"
              onChange={i => setFormData({ ...formData, name: i.target.value })}
            />

            <br />

            <Form.Check
              type="switch"
              id="tos-agree"
              onChange={handleTosAgreeSwitchEvent}
              label={
              <p style={{fontSize: '12px'}}>
                I have read and agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/terms-of-service" target="_blank">Terms of Service</a>
              </p>
              }
            />

          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={ !tosAgree || formData.name.length < 5 || submitted }
          onClick={formSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default NewOrganizationModal