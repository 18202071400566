import React from "react";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

function ContactUs() {
  return (
    <div
      style={{
        textAlign: "left",
        paddingTop: "70px",
        paddingBottom: "100px",
        paddingLeft: "20%",
        paddingRight: "20%"
      }}
    >
      <h1>Reach out!</h1>
      <br />

      Have questions or comments? Shoot us an email <b>support@protean-labs.io</b><CopyToClipboardButton
      data="support@protean-labs.io" />
      <br />
      Wanna talk shop? <b>john@protean-labs.io</b><CopyToClipboardButton data="john@protean-labs.io" />

      <br />
      <br />

      <h4>Join our <a href="https://discord.gg/dVT57vBufM"
                      style={{ textDecoration: "none", color: "#5661ea" }}>Discord</a> server too!</h4>

      <br />
      <br />

      <h4>Legal</h4>
      <sub><b>legal@protean-labs.io</b></sub>
      <br />

    </div>
  );
}

export default ContactUs;
